.logoContainer {
  padding: 16px;
}

.logo {
  width: 100%;
}

.content {
  padding: 24px;
  min-height: 360px;
  background: #fff;
  border-radius: 8px;
}
