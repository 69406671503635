.container {
  position: relative;
  background: rgb(5, 1, 77);
  background: linear-gradient(
    0deg,
    rgba(5, 1, 77, 1) 0%,
    rgba(12, 12, 158, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  height: 100vh;
}

.formContainer {
  position: static;
  z-index: 2;
  background-color: rgba(4, 73, 163, 0.8);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.fish {
  left: 0;
  bottom: 5em;
  overflow: visible;
  width: 235px;
  height: 104px;
  margin-left: -235px;
  position: absolute;
  animation: swim 32s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes swim {
  0% {
    margin-left: -235px;
  }
  90% {
    margin-left: 100%;
  }
  100% {
    margin-left: 100%;
  }
}

@keyframes swim {
  0% {
    margin-left: -235px;
  }
  70% {
    margin-left: 100%;
  }
  100% {
    margin-left: 100%;
  }
}

.fishSvg {
  width: 235px;
  height: 104px;
  margin-left: -235px;
  position: absolute;
  animation: swim 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.fish1,
.fish2,
.fish3,
.fish4,
.fish5,
.fish6 {
  fill: #05014d;
  animation: bounce 2s infinite;
}

.fish2 {
  animation-delay: 0.5s;
}

.fish3 {
  animation-delay: 0.2s;
}

.fish4 {
  animation-delay: 0.4s;
}

.fish5 {
  animation-delay: 0.1s;
}

.fish6 {
  animation-delay: 0.3s;
}

@-moz-keyframes bounce {
  0%,
  50%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  75% {
    transform: translateY(-3px);
  }
}
@-webkit-keyframes bounce {
  0%,
  50%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  75% {
    transform: translateY(-3px);
  }
}
@keyframes bounce {
  0%,
  50%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  75% {
    transform: translateY(-3px);
  }
}
